<template>
	<SectionHeader :image="null" title="Kontakt" page="So erreichen Sie uns" />
	<div class="container">
		<div class="alert alert-info" role="alert">Bisher ist kein Inhalt hinterlegt</div>
	</div>
</template>
<script>
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: 'Kontakt',
	components: {
		SectionHeader
	}
}
</script>
